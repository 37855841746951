var apibaseurl = "https://3t23rkamvh.execute-api.ap-south-1.amazonaws.com/Prod/api"
var api_base_url = "https://3t23rkamvh.execute-api.ap-south-1.amazonaws.com/Prod/api"

//var apibaseurl = "https://localhost:44331/api"
//var api_base_url = "https://localhost:44331/api"

import axios from 'axios'


export default {

    Login(data) {
        return axios.post(apibaseurl + "/Login",data);
    },
    Add_Schools(data) {
        return axios.post(apibaseurl + "/Schools",data);
    },
    list_AcademicYears(data) {
        return axios.get(apibaseurl + "/AcademicYears",data);
    },
    add_AcademicYears(data) {
        return axios.post(apibaseurl + "/AcademicYears",data);
    },
    add_ClassMasters(data) {
        return axios.post(apibaseurl + "/ClassMasters",data);
    },
    list_ClassMasters(data) {
        return axios.get(apibaseurl + "/ClassMasters",data);
    },
    add_DivisionMasters(data) {
        return axios.post(apibaseurl + "/DivisionMasters",data);
    },
    list_DivisionMasters(data) {
        return axios.get(apibaseurl + "/DivisionMasters",data);
    },
    list_Teachers(data) {
        return axios.get(apibaseurl + "/Teachers",data);
    },
    add_Teachers(data) {
        return axios.post(apibaseurl + "/Teachers",data);
    },
    list_Subjects(data) {
        return axios.get(apibaseurl + "/Subjects",data);
    },
    add_Subjects(data) {
        return axios.post(apibaseurl + "/Subjects",data);
    },
    list_Students(data) {
        return axios.get(apibaseurl + "/Students",data);
    },
    add_Students(data) {
        return axios.post(apibaseurl + "/Students",data);
    },
    list_Schools() {
        return axios.get(apibaseurl + "/Schools");
    },
    add_Schools(data) {
        return axios.post(apibaseurl + "/Schools",data);
    },
    list_studentClassMapping(data) {
        return axios.get(apibaseurl + "/studentClassMapping",data);
    },
    add_studentClassMapping(data) {
        return axios.post(apibaseurl + "/studentClassMapping",data);
    },
    list_TeacherSubjectMappings(data) {
        return axios.get(apibaseurl + "/TeacherSubjectMappings",data);
    },
    add_TeacherSubjectMappings(data) {
        return axios.post(apibaseurl + "/TeacherSubjectMappings",data);
    },
    add_PreSignedUrl(data) {
        return axios.post(apibaseurl + "/PreSignedUrl",data);
    },
    list_Home() {
        return axios.get(apibaseurl + "/Home");
    },
    role_list() {
        return axios.get(apibaseurl + "/UserRoles");
    },
    register_user(data){
        return axios.post(apibaseurl + "/UserDetails",data);
    },
    studentbyid(data){
        return axios.get(apibaseurl + "/Students",data);
    },
    teacherbyid(data){
        return axios.get(apibaseurl + "/Teachers",data);
    },
    user_details() {
        return axios.get(apibaseurl + "/UserDetails");
    },
    add_content(data){
        return axios.post(apibaseurl + "/Contents",data);
    },
    get_url(data){
        return axios.post(apibaseurl + "/PreSignedUrl",data);
    },
    dashboard_data() {
        return axios.get(apibaseurl + "/GetData");
    },
    latest_data(data) {
        return axios.get(apibaseurl + "/Contents/GetLatestContent",data);
    },
    teacher_latest_data(data) {
        return axios.get(apibaseurl + "/Contents/GetLatestContentTeacher/",+data);
    },
    get_profile(data){
        return axios.post(apibaseurl + "/GetStudentRegistration",data);
    },

}